import React, { useState, useRef, useEffect } from 'react';
import Modal from 'react-modal';
import SignatureCanvas from 'react-signature-canvas';
import './firebase.js';
import { getDatabase, ref as databaseRef, onValue, set, push, update, onDisconnect, serverTimestamp } from 'firebase/database';
import { getStorage, ref , uploadString, getDownloadURL, deleteObject } from 'firebase/storage';

const storage = getStorage();
const database = getDatabase();

function convertUsernameToName(username) {
    // Split the username into an array of words
    const words = username.split('-');
  
    // Capitalize the first letter of each word
    const capitalizedWords = words.map(word => word.charAt(0).toUpperCase() + word.slice(1));
  
    // Join the words back into a string with spaces
    const name = capitalizedWords.join(' ');
  
    return name;
  }

  function formatNumberWithCommas(number) {
    let roundedNumber = Math.floor(number);
    // Convert the number to a string
    let numString = roundedNumber.toString();
  
    // Insert commas at the specified positions
    if (numString.length > 7) {
        numString = numString.slice(0, -7) + ',' + numString.slice(-7, -5) + ',' + numString.slice(-5, -3) + ',' + numString.slice(-3);
    } else if (numString.length > 5) {
        numString = numString.slice(0, -5) + ',' + numString.slice(-5, -3) + ',' + numString.slice(-3);
    } else if (numString.length > 3) {
        numString = numString.slice(0, -3) + ',' + numString.slice(-3);
    }
  
    return numString;
  }

Modal.setAppElement('#root'); // Set the root element for accessibility

const HTMLPopup = ({ isOpen, onClose, user, onSubmit }) => {
    const [htmlContent, setHtmlContent] = useState('');
    const [isSignatureMode, setIsSignatureMode] = useState(false);
    const [signatureImage, setSignatureImage] = useState('');
    const [hideSubmitCancel, setHideSubmitCancel] = useState(false);
    const signatureRef = useRef();
  
    const canvasBorderStyle = {
        border: '2px solid #6AA5A9', // Adjust the border style as needed
        borderRadius: '8px', // Optional: Add border radius for rounded corners
    };
        
    useEffect(() => {
      // Check if the signature image exists in Firebase Storage
      const filename = `${user.username}/signature.png`;
      const storageRef = ref(storage, filename);
  
      getDownloadURL(storageRef)
        .then((downloadURL) => {
          // If the image exists, set it as the signatureImage state
          setSignatureImage(downloadURL);
        })
        .catch((error) => {
          // If the image doesn't exist, do nothing or handle as needed
          //console.error('Error checking for existing signature:', error);
        });
  
      if (isOpen) {
        // Display static HTML content with dynamic value
        setHtmlContent(`
        <html>
        <head>
          <meta content="text/html; charset=UTF-8" http-equiv="content-type" />
          <style type="text/css">
            @import url(https://themes.googleusercontent.com/fonts/css?kit=XXYeIXTmm2tIfU9VLhWLlDBlRcrgQcF4OphYIZpUYXltdRpqh5C4jLKpUqQBOJCYIm8jHuLpH7z76X2BKpCDFg);
            ol {
              margin: 0;
              padding: 0;
            }
            table td,
            table th {
              padding: 0;
            }
            .c2 {
              color: #e31c60;
              font-weight: 400;
              text-decoration: none;
              vertical-align: baseline;
              font-size: 10pt;
              font-family: "Roboto Condensed";
              font-style: normal;
            }
            .c10 {
              margin-left: -0.8pt;
              padding-top: 0pt;
              padding-bottom: 0pt;
              line-height: 1.5;
              orphans: 2;
              widows: 2;
              text-align: left;
            }
            .c11 {
              margin-left: -0.8pt;
              padding-top: 10pt;
              padding-bottom: 0pt;
              line-height: 1.0;
              orphans: 2;
              widows: 2;
              text-align: left;
            }
            .c0 {
              margin-left: -0.8pt;
              padding-top: 20pt;
              padding-bottom: 0pt;
              line-height: 1.5;
              orphans: 2;
              widows: 2;
              text-align: left;
            }
            .c8 {
              margin-left: -0.8pt;
              padding-top: 24pt;
              padding-bottom: 0pt;
              line-height: 1.5;
              orphans: 2;
              widows: 2;
              text-align: left;
            }
            .c6 {
              color: #424242;
              font-weight: 400;
              font-size: 36pt;
              font-family: "Oswald";
            }
            .c13 {
              color: #666666;
              font-weight: 400;
              font-size: 10pt;
              font-family: "Source Code Pro";
            }
            .c1 {
              font-size: 10pt;
              font-family: "Source Code Pro";
              color: #424242;
              font-weight: 400;
            }
            .c5 {
              text-decoration: none;
              vertical-align: baseline;
              font-style: normal;
            }
            .c3 {
              background-color: #ffffff;
              max-width: 468pt;
              padding: 36pt 72pt 36pt 72pt;
            }
            .c12 {
              font-family: "Roboto Condensed";
              color: #e31c60;
              font-weight: 400;
            }
            .c9 {
              height: 10pt;
            }
            .c7 {
              font-style: italic;
            }
            .c4 {
              font-weight: 700;
            }
            .title {
              padding-top: 10pt;
              color: #424242;
              font-size: 36pt;
              padding-bottom: 0pt;
              font-family: "Oswald";
              line-height: 1.0;
              page-break-after: avoid;
              orphans: 2;
              widows: 2;
              text-align: left;
            }
            .subtitle {
              padding-top: 20pt;
              color: #666666;
              font-size: 10pt;
              padding-bottom: 0pt;
              font-family: "Roboto Condensed";
              line-height: 1.5;
              page-break-after: avoid;
              orphans: 2;
              widows: 2;
              text-align: left;
            }
            li {
              color: #424242;
              font-size: 10pt;
              font-family: "Source Code Pro";
            }
            p {
              margin: 0;
              color: #424242;
              font-size: 10pt;
              font-family: "Source Code Pro";
            }
            h1 {
              padding-top: 20pt;
              color: #424242;
              font-size: 16pt;
              padding-bottom: 10pt;
              font-family: "Oswald";
              line-height: 1.5;
              page-break-after: avoid;
              orphans: 2;
              widows: 2;
              text-align: left;
            }
            h2 {
              padding-top: 24pt;
              color: #e31c60;
              font-size: 10pt;
              padding-bottom: 0pt;
              font-family: "Oswald";
              line-height: 1.5;
              page-break-after: avoid;
              orphans: 2;
              widows: 2;
              text-align: left;
            }
            h3 {
              padding-top: 20pt;
              color: #424242;
              font-size: 13pt;
              padding-bottom: 0pt;
              font-family: "Source Code Pro";
              line-height: 1.0;
              page-break-after: avoid;
              orphans: 2;
              widows: 2;
              text-align: left;
            }
            h4 {
              padding-top: 8pt;
              -webkit-text-decoration-skip: none;
              color: #666666;
              text-decoration: underline;
              font-size: 11pt;
              padding-bottom: 0pt;
              line-height: 1.5;
              page-break-after: avoid;
              text-decoration-skip-ink: none;
              font-family: "Trebuchet MS";
              orphans: 2;
              widows: 2;
              text-align: left;
            }
            h5 {
              padding-top: 8pt;
              color: #666666;
              font-size: 11pt;
              padding-bottom: 0pt;
              font-family: "Trebuchet MS";
              line-height: 1.5;
              page-break-after: avoid;
              orphans: 2;
              widows: 2;
              text-align: left;
            }
            h6 {
              padding-top: 8pt;
              color: #666666;
              font-size: 11pt;
              padding-bottom: 0pt;
              font-family: "Trebuchet MS";
              line-height: 1.5;
              page-break-after: avoid;
              font-style: italic;
              orphans: 2;
              widows: 2;
              text-align: left;
            }
          </style>
        </head>
        <body class="c3 doc-content">
          <div>
            <p class="c0 c9">
              <span class="c1 c5"></span>
            </p>
          </div>
          <p class="c11 title" id="h.lfnnqlbxlyqh"><span>ACHINTYA</span></p>

          <h1 class="c11" id="h.lfnnqlbxlyqh"><span>Non-Disclosure Agreement</span></h1>
          <p class="c10">
            <span
              style="overflow: hidden; display: inline-block; margin: 0.00px 0.00px; border: 0.00px solid #000000; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px); width: 624.00px; height: 5.33px;"
            ><img
                alt=""
                src="images/image1.png"
                style="width: 624.00px; height: 5.33px; margin-left: 0.00px; margin-top: 0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
                title="horizontal line"
            /></span>
          </p>
          <p class="c10 c9">
            <span class="c1 c5"></span>
          </p>
          <p class="c10">
            <span class="c12">Jan 8, 2024</span>
          </p>
          <p class="c8">
            <span>To</span><span class="c1">&nbsp;${convertUsernameToName(user.username)}, </span>
          </p>
          <p class="c0">
            <span>This document is to be considered as the non-disclosure agreement (NDA) between you and the registered entity </span
            ><span class="c7">Rcommndr</span><span>&nbsp;with the registration number </span
            ><span class="c7">BCAPA1242DSD001</span
            ><span>. This NDA pertains to the confidential information shared with you in the course of your investment activities in its business under the name Achintya.</span>
          </p>
          <p class="c0">
            <span>Based on your access to confidential information, you agree not to disclose, reproduce, or use such information for any purpose other than the evaluation of your investment. This NDA remains effective indefinitely, and you are obliged to keep the information confidential even after the termination of your investment relationship.</span>
          </p>
          <p class="c0">
            <span>By signing this NDA, you acknowledge your legal responsibility to protect the confidentiality of the information provided by Achintya. </span>
          </p>
          <p class="c0">
            <span class="c1">To be signed by, &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span
            ><span>&nbsp; &nbsp; &nbsp; &nbsp;</span>
          </p>
          <p class="c0">
            <span class="c2">
            ${convertUsernameToName(user.username)} &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
            </span>
          </p>
        </body>
        </html>
        
      
        `);
      }
    }, [isOpen, user]);
  
    const handleDeleteSignature = () => {
        // Replace the path with username/signature.png
        const filename = `${user.username}/signature.png`;

        // Get a reference to the Firebase Storage location
        const storageRef = ref(storage, filename);

        // Delete the signature file
        deleteObject(storageRef)
            .then(() => {
            //console.log('Signature deleted successfully!');
            setSignatureImage(''); // Clear the displayed signature image
            })
            .catch((error) => {
            console.error('Error deleting signature:', error);
            });
    };
        
    const handleOpenSignatureMode = () => {
      setIsSignatureMode(true);
    };
  
    const handleClosePopup = () => {
      // Close the popup without submitting the signature
      setIsSignatureMode(false);
      setSignatureImage('');
      setHtmlContent(''); // Clear the HTML content
      onClose();
    };
  
    const handleSignatureSubmit = () => {
        if (!signatureRef || !signatureRef.current) {
            const myRef = databaseRef(database, 'users/' + user.username);
            
            user.acceptedNDA = true;
            localStorage.setItem('currentUser', JSON.stringify(user));
            console.log(myRef)
            update(myRef, {acceptedNDA: true});
            setHideSubmitCancel(true);
            return; 
        }
      
        const image = signatureRef.current.toDataURL();
      
        // Calculate the position of the signature within the modal
        const signaturePosition = {
          left: signatureRef.current.getCanvas().offsetLeft,
          top: signatureRef.current.getCanvas().offsetTop,
        };  

        // Replace the path with username/signature.png
        const filename = `${user.username}/signature.png`;
      
        // Get a reference to the Firebase Storage location
        const storageRef = ref(storage, filename);
      
        // Check if the signature file already exists
        getDownloadURL(storageRef)
          .then((existingDownloadURL) => {
            // If the file exists, delete it
            const existingSignatureRef = ref(storage, existingDownloadURL);
            return deleteObject(existingSignatureRef);
          })
          .catch((error) => {
            // Ignore errors if the file doesn't exist
            if (error.code !== 'storage/object-not-found') {
              console.error('Error checking existing signature:', error);
            }
          })
          .then(() => {
            // Proceed with uploading the new signature
      
            // Append the signature image with absolute positioning
            const updatedHtmlContent = `${htmlContent}<img src="${image}" alt="User Signature" style="position: absolute; left: ${signaturePosition.left}px; top: ${signaturePosition.top}px;" />`;
      
            // Pass the updated HTML content to the parent component
            // onSubmit(updatedHtmlContent);
      
            // Display the signature image instead of the canvas
            setSignatureImage(image);
            console.log(user.username);
            const myRef = databaseRef(database, 'users/' + user.username);
            
            user.acceptedNDA = true;
            localStorage.setItem('currentUser', JSON.stringify(user));
            console.log(myRef)
            update(myRef, {acceptedNDA: true});
      
            // Upload the data URL as a string to Firebase Storage
            return uploadString(storageRef, image, 'data_url');
          })
          .then((snapshot) => {
            //console.log('Signature uploaded successfully!');
      
            // If needed, you can store the downloadURL in Firebase Realtime Database
            // const databaseRef = ref(database, 'signatures');
            // push(databaseRef, downloadURL);
      
            // If needed, you can also pass the downloadURL to the parent component using onSubmit
            //onSubmit(updatedHtmlContent);
          })
          .catch((error) => {
            // Handle errors
            console.error('Error handling signature:', error);
          });
      };
      
      console.log(isSignatureMode, user.acceptedNDA, signatureImage )
      return (
        <Modal
          isOpen={isOpen}
          onRequestClose={handleClosePopup}
          contentLabel="Popup"
        >
          <div>
            {/* Display static HTML content with dynamic value */}
            <div dangerouslySetInnerHTML={{ __html: htmlContent }} />
    
            <div>
                <div>
                {!isSignatureMode && !user.acceptedNDA ? (
                    <>
                        <button
                            onClick={handleOpenSignatureMode}
                            style={{
                                background: '#6AA5A9',
                                color: 'white',
                                border: 'none',
                                fontSize: 16,
                                cursor: 'pointer',
                                padding: 8,
                                marginTop: 10
                            }}
                        >
                            Sign here
                        </button>
                    </>
                    ) : isSignatureMode && !user.acceptedNDA && !signatureImage ? (
                        <SignatureCanvas ref={signatureRef} canvasProps={{ width: 300, height: 150, style: canvasBorderStyle }} />
                    ) : ( (isSignatureMode && !user.acceptedNDA) || user.acceptedNDA) && signatureImage ? (
                    <>
                        <img src={signatureImage} alt="User Signature" />
                        {/* Uncomment the next line if you want to allow users to delete their signature */}
                        {/* <button onClick={handleDeleteSignature}>Delete Signature</button> */}
                    </>
                ) : null}
                </div>
                <div>
                    {isSignatureMode && !user.acceptedNDA && !hideSubmitCancel ? 
                        <>
                            <br />
                            <button
                                className='font-family'
                                onClick={handleSignatureSubmit}
                                style={{
                                background: '#6AA5A9',
                                color: 'white',
                                border: '1px solid',
                                fontSize: '16px',
                                cursor: 'pointer',
                                padding: '5px 10px',
                                margin: '5px'
                                }}
                            >
                                Submit signature
                            </button>
                            <button
                            className='font-family'
                            onClick={() => setIsSignatureMode(false)}
                            style={{
                                background: 'white',
                                color: 'black',
                                border: '1px solid',
                                fontSize: '16px',
                                cursor: 'pointer',
                                padding: '3px'
                            }}
                            >
                            Cancel
                            </button>

                        </> : null}
                </div>
            </div>
    
            {/* Always display the "Close Popup" button */}
            <button
                className='font-family'
                onClick={handleClosePopup}
                style={{
                    background: 'white',
                    color: 'black',
                    border: '1px solid',
                    fontSize: '16px',
                    cursor: 'pointer',
                    padding: '5px',
                    paddingBottom: '5px',
                    float: 'right',
                    marginTop: -30
                }}
                >
                Close
            </button>
          </div>
        </Modal>
      );
    };
    
    export default HTMLPopup;