import { useState, useEffect } from 'react';
import logo from './logo.png';
import './App.css';
import './firebase.js';
import HTMLPopup from './HTMLPopup';
import NDAPopup from './NDAPopup'; 
import { getDatabase, ref, onValue, set, push, update, onDisconnect, serverTimestamp } from 'firebase/database';
import { getAuth, createUserWithEmailAndPassword, signInWithEmailAndPassword, signOut, onAuthStateChanged } from 'firebase/auth';
import { getStorage, ref as storageRef , uploadString, getDownloadURL, deleteObject } from 'firebase/storage';
import { BrowserRouter as Router, Route, Routes, Link, useParams } from 'react-router-dom';
import UserSpace from './UserSpace'; // Import UserSpace from the new file
import WebRTCVideo from './WebRTCVideo'; // Import WebRTCVideo
import PlayChunks from './PlayChunks.js';


const auth = getAuth();
const database = getDatabase();
const storage = getStorage();

function getDayAndMonthFromDayNumber(dayNumber) {
  // Set the target date to January 1, 2024
  let targetDate = new Date(2024, 0, 1);

  // Calculate the new date by adding the dayNumber
  let newDate = new Date(targetDate);
  newDate.setDate(targetDate.getDate() + dayNumber - 1);

  // Create an array of month names
  let monthNames = [
    "January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
  ];

  // Extract day and month
  let day = newDate.getDate();
  let monthIndex = newDate.getMonth();
  let month = monthNames[monthIndex];

  return { day, month };
}

function formatNumberWithCommas(number) {
  let roundedNumber = Math.floor(number);
  // Convert the number to a string
  let numString = roundedNumber.toString();

  // Insert commas at the specified positions
  if (numString.length > 7) {
      numString = numString.slice(0, -7) + ',' + numString.slice(-7, -5) + ',' + numString.slice(-5, -3) + ',' + numString.slice(-3);
  } else if (numString.length > 5) {
      numString = numString.slice(0, -5) + ',' + numString.slice(-5, -3) + ',' + numString.slice(-3);
  } else if (numString.length > 3) {
      numString = numString.slice(0, -3) + ',' + numString.slice(-3);
  }

  return numString;
}

const myRef = ref(database, 'users/anothermohit');
//console.log(myRef)
const myConnectionsRef = ref(database, 'users/anothermohit/connections');
const lastOnlineRef = ref(database, "users/anothermohit/lastOnline");
const connectedRef = ref(database, ".info/connected");
onValue(connectedRef, (snap) => {
  if (snap.val() === true) {
    // We're connected (or reconnected)! Do anything here that should happen only if online (or on reconnect)

    const con = push(myConnectionsRef);
    update(myRef, {cameOnline: serverTimestamp()});

    //console.log(myConnectionsRef, con);

    // When I disconnect, remove this device
    onDisconnect(con).remove();
 
    // Add this device to my connections list
    // this value could contain info about the device or a timestamp too
    set(con, true);

    // When I disconnect, update the last time I was seen online
    onDisconnect(lastOnlineRef).set(serverTimestamp());
  }
});

const offsetRef = ref(database, ".info/serverTimeOffset");
onValue(offsetRef, (snap) => {
  const offset = snap.val();
  const estimatedServerTimeMs = new Date().getTime() + offset;
  //console.log(offset, estimatedServerTimeMs);
});

function convertUsernameToName(username) {
  // Split the username into an array of words
  const words = username.split('-');

  // Capitalize the first letter of each word
  const capitalizedWords = words.map(word => word.charAt(0).toUpperCase() + word.slice(1));

  // Join the words back into a string with spaces
  const name = capitalizedWords.join(' ');

  return name;
}

function App() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [loginState, setLoginState] = useState('login');
  const [errorState, setErrorState] = useState('');
  const [user, setUser] = useState(null);
  const [word, setWord] = useState('');
  const [currentUser, setCurrentUser] = useState(null);
  const [turnRequest, setTurnRequest] = useState(null);
  const [isHtmlModalOpen, setIsHtmlModalOpen] = useState(false);
  const [isNDAModalOpen, setIsNDAModalOpen] = useState(false);
  const [updatedHtmlContent, setUpdatedHtmlContent] = useState('');

  const openHtmlModal = () => {
    setIsHtmlModalOpen(true);
  };

  const closeHtmlModal = () => {
    setIsHtmlModalOpen(false);
  };

  const openNDAModal = () => {
    setIsNDAModalOpen(true);
  };

  const closeNDAModal = () => {
    setIsNDAModalOpen(false);
  };

  const handleSignatureSubmit = (htmlContent) => {
    // Handle the updated HTML content in the main component
    setUpdatedHtmlContent(htmlContent);
  };

  const handleRequest = (guestUsername) => {
    setTurnRequest(guestUsername);
  };

  const handleTakeTurn = (guestUsername) => {
    if (currentUser && currentUser.email === `${username}@achintya.org`) {
      const hostRef = ref(database, `users/${username}`);
      const guestRef = ref(database, `users/${guestUsername}`);
      update(hostRef, { turn: guestUsername });
      update(guestRef, { requested: false });
    }
  };

  /*
  useEffect(() => {
    const turnRequestRef = ref(database, `users/${username}/requested`);
    const unsubscribe = onValue(turnRequestRef, (snapshot) => {
      const requested = snapshot.val();
      setTurnRequest(requested);
    });

    return () => {
      unsubscribe();
    };
  }, [username]);
  */

  useEffect(() => {
    let currentUserState = localStorage.getItem('currentUser');
    if (currentUserState) {
      let user = JSON.parse(currentUserState);
      setCurrentUser(user);
    }

    /*
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      //console.log(user);
      setCurrentUser(user);
    });

    return () => {
      unsubscribe();
    };
    */
  }, []);

  const handleWordChange = (e) => {
    setWord(e.target.value);
  };

  const handleKeyPress = (e) => {
    // Check if the pressed key is the space bar
    if (e.key === ' ' && word.trim() !== '') {
      const userWordRef = push(ref(database, `users/${username}/word`));
      const userWordCurrentRef = ref(database, `users/${username}/word`);
      set(userWordRef, { word: word.trim(), timestamp: serverTimestamp() });
      update(userWordCurrentRef, { word: word.trim(), timestamp: serverTimestamp() });
      setWord(''); // Clear the input after submitting the word
    }
  };

  const handleSignIn = async () => {
    const email = `${username}@achintya.org`; // Append a placeholder domain
    try {
      await signInWithEmailAndPassword(auth, email, password);

      // Create or update the user node with online status
      const userNodeRef = ref(database, `users/${username}`);
      update(userNodeRef, { online: true });
      onDisconnect(userNodeRef).update({ online: false });

    } catch (error) {
      if (error.code === 'auth/invalid-login-credentials') {
        try {
          const userCredential = await createUserWithEmailAndPassword(auth, email, password);
          const newUser = userCredential.user;
          //console.log('User created and signed in:', newUser.uid);

          // Create the user node with online status
          const userNodeRef = ref(database, `users/${username}`);
          set(userNodeRef, { online: true });
        } catch (error) {
          console.error('Error creating user:', error.message);
        }
      } else {
        console.error('Error signing in:', error.message);
      }
    }
  };

  const handleSignOut = async () => {
    try {
      // Update the user node to set online status to false
      if (user) {
        const userNodeRef = ref(database, `users/${username}`);
        update(userNodeRef, { online: false });
      }
      await signOut(auth);
    } catch (error) {
      console.error('Error signing out:', error.message);
    }
  };

  let currentUsername = '';
 
  /*if (currentUser) 
     currentUsername = currentUser.email.replace('@achintya.org', '')
  */
  
  //if (currentUser )console.log(currentUser);

  let checkState = null;
  let currentUserState = localStorage.getItem('currentUser');
  if (currentUserState) checkState = JSON.parse(currentUserState);
  //console.log(checkState, loginState, currentUserState);

  // Get the current date
  let currentDate = new Date();

  // Set the target date to January 1, 2024
  let targetDate = new Date(2024, 0, 1);

  // Calculate the difference in milliseconds
  let timeDifference = currentDate - targetDate;

  // Convert the difference to days
  let dayNumber = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));

  //console.log("Day number of 2024 from current date:", dayNumber);

  if (currentUser && !currentUser.principal) currentUser.principal = 0;
  if(currentUser) console.log(currentUser.profit*100000)
  return (
    <Router>
      <div className="App">
        {checkState && loginState != 'login' ? (
          <div>
            {currentUser ? 
              <div className='font-family' style={{ textAlign: 'center', margin: '20px auto', fontFamily: 'Arial, sans-serif', border: '2px solid #ccc', borderRadius: '10px', padding: '20px', maxWidth: '500px' }}>
  <div style={{ marginBottom: '20px' }}>
    <p style={{ color: 'black', fontSize: '24px', fontWeight: 'bold', margin: '0' }}>{convertUsernameToName(currentUser.username)}</p>
  </div>

  {currentUser.profit ? 
  <div style={{ background: 'lightgray', borderRadius: '8px', padding: '10px', marginBottom: '20px' }}>
    <p style={{ color: 'black', fontSize: '18px', margin: '0', fontWeight: 'bold' }}>Interest <span sytle={{fontWeight: 100}}>(until December 31, 2023)</span></p>
    <p style={{ color: 'black', fontSize: '18px', margin: '0', paddingTop: 10, fontWeight: '200' }}>INR {formatNumberWithCommas(currentUser.profit * 100000)}</p>
    

     <p className='font-family' style={{  color: 'black', fontSize: '14px', margin: '0', paddingTop: 10, fontWeight: '200' }}>Your settlement has been transferred to your bank account.</p>

  </div>
  : null }
  
  <div style={{ background: 'lightgray', borderRadius: '8px', padding: '10px', marginBottom: '20px' }}>
    <p className='font-family' style={{ color: 'black', fontSize: '18px', margin: '0', fontWeight: 'bold' }}>Current Principal</p>
    <p className='font-family' style={{ color: 'black', fontSize: '18px', margin: '0', paddingTop: 10, fontWeight: '200' }}>INR {formatNumberWithCommas(currentUser.principal * 100000)}</p>
  </div>

  <div style={{ background: 'lightgray', borderRadius: '8px', padding: '10px', marginBottom: '20px' }}>
  <p className='font-family' style={{ color: 'black', fontSize: '18px', margin: '0', fontWeight: 'bold' }}>
  {currentUser.interest ?
    `Interest` 
    : currentUser.withdrawnOn ? 
    `Interest (until ${getDayAndMonthFromDayNumber(currentUser.withdrawnOn).month} ${getDayAndMonthFromDayNumber(currentUser.withdrawnOn).day}, 2024)` 
    : 'Interest (since January 1, 2024)'
  }
</p>
{/*
<p className='font-family' style={{ color: 'black', fontSize: '18px', margin: '0', paddingTop: 10, fontWeight: '200' }}>
  INR {formatNumberWithCommas(currentUser.investedOn ? currentUser.principal * (dayNumber-currentUser.investedOn)* 1000 : currentUser.withdrawnOn ? currentUser.withdrawnPrincipal * (currentUser.withdrawnOn)* 1000 : currentUser.principal * dayNumber*1000)}
</p>  
*/}
<p className='font-family' style={{ color: 'black', fontSize: '18px', margin: '0', paddingTop: 10, fontWeight: '200' }}>
  INR {formatNumberWithCommas(currentUser.interest ? (currentUser.interest)* 100000 : currentUser.withdrawnOn ? currentUser.withdrawnPrincipal * (currentUser.withdrawnOn)* 1000 : currentUser.principal * dayNumber*1000)}
</p>
</div>

{currentUser.word ?
    `${currentUser.word} ` 
    : ''
  }

  <div>
    <button
      className='font-family'
      onClick={openNDAModal}
      style={{
        background: '#6AA5A9',
        color: 'white',
        border: 'none',
        fontSize: '16px',
        cursor: 'pointer',
        padding: '5px 10px',
        margin: '5px'
      }}
    >
      {currentUser.acceptedNDA ? 'NDA Accepted' : 'Accept NDA'}
     </button>
     <br /> 

     <br />
    <button
      onClick={() => {
        localStorage.setItem('currentUser', '');
        setLoginState('login');
      }}
      style={{
        background: 'black',
        color: 'white',
        border: 'none',
        fontSize: '16px',
        cursor: 'pointer',
        padding: '10px 20px',
        marginBottom: '10px',
      }}
    >
      Sign out
    </button>

    <HTMLPopup
      isOpen={isHtmlModalOpen}
      onClose={closeHtmlModal}
      user={currentUser}
      onSubmit={handleSignatureSubmit}
    />

    <NDAPopup
      isOpen={isNDAModalOpen}
      onClose={closeNDAModal}
      user={currentUser}  // Pass the user object to NDAPopup
    />

    {/* Display the updated HTML content */}
    <div style={{ fontSize: '14px', marginTop: '20px' }} dangerouslySetInnerHTML={{ __html: updatedHtmlContent }} />
  </div>
</div>



            : null}
          </div>
        ) :
        (<header className="App-header">
        <Link to="/"><img src={logo} style={{marginTop: -40}} className="App-logo" alt="logo" /></Link>
          <p  style={{fontWeight: 500, fontSize: 36, color: '#000'}}>Achintya</p>

          <p  style={{fontWeight: 200,color: '#000', marginTop: -10}}>The silent revolution to take the world from Internet to Innernet!</p>
          {/*currentUser ? <p>You - {currentUsername}!</p> : null*/}

          {loginState === 'login' ? (
            <button
              onClick={() => {
                setLoginState('username');
                setErrorState('');
              }}
              style={{
                background: 'black',
                color: 'white',
                border: 'none',
                fontSize: 16,
                cursor: 'pointer',
                padding: 16,
              }}
            >
              Log in
            </button>
        ) : loginState === 'username' ? (
          <div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <input
                autoFocus
                className="font-style"
                style={{
                  borderWidth: 1,
                  background: 'white',
                  color: 'black',
                  fontSize: 16,
                  cursor: 'pointer',
                  padding: 16,
                  flex: 1,
                  marginRight: '5px', // Add margin to create space between input and border
                }}
                type="text"
                placeholder="username..."
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    let checkRef = ref(database, 'users/' + username);
                    onValue(checkRef, (snapshot) => {
                      if (!snapshot.val()) setErrorState('username');
                      else {
                        setLoginState('password');
                        setErrorState('');
                      }
                    });
                  } else if (!username) {
                    setErrorState('');
                  }
                }}
              />
              <div
                style={{
                  height: 47,
                  border: '1px solid black',
                  display: 'flex',
                  alignItems: 'center',
                  padding: '2px',
                  borderRadius: '5px',
                  cursor: 'pointer'
                }}
                onClick={() => {
                  let checkRef = ref(database, 'users/' + username);
                  onValue(checkRef, (snapshot) => {
                    if (!snapshot.val()) setErrorState('username');
                    else {
                      setLoginState('password');
                      setErrorState('');
                    }
                  });
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="black"
                >
                  <path d="M5 12L19 12M12 5l7 7-7 7" />
                </svg>
              </div>
            </div>
            {username && errorState ? <p className="font-style" style={{color: '#C7130F', fontSize: 12}}>Invalid username</p> : null}
          </div>
        ) : loginState === 'password' ? (
          <div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <input
                autoFocus
                className="font-style"
                style={{
                  borderWidth: 1,
                  background: 'white',
                  color: 'black',
                  fontSize: 16,
                  cursor: 'pointer',
                  padding: 16,
                  flex: 1,
                  marginRight: '5px', // Add margin to create space between input and border
                }}
                type="password"
                placeholder="password..."
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    let checkRef = ref(database, 'users/' + username);
                    onValue(checkRef, (snapshot) => {
                      let snap = snapshot.val();
                      if (snap.password == password) {
                        setLoginState('in');
                        setCurrentUser(snap);
                        snap.username = username;
                        localStorage.setItem('currentUser', JSON.stringify(snap));
                        setErrorState('');
                      } else {
                        setErrorState('password');
                      }
                    });

                    // total profit, principal
                    let checkRefs = ref(database, 'users/');
                    onValue(checkRefs, (snapshot) => {
                      let data = snapshot.val();
                      //console.log(data);

                      let total_principal = 0;
                      let total_profit = 0;

                      for (let key in data) {
                          let value = data[key];
                          if (value.hasOwnProperty("principal")) {
                              total_principal += value.principal;
                          }
                          if (value.hasOwnProperty("profit")) {
                              total_profit += value.profit;
                          }
                      }

                      //console.log("Total Principal:", total_principal);
                      //console.log("Total Profit:", total_profit);

                    });

                  } else if (!password) {
                    setErrorState('');
                  }
                }}
              />
              <div
                style={{
                  height: 47,
                  border: '1px solid black',
                  display: 'flex',
                  alignItems: 'center',
                  padding: '2px',
                  borderRadius: '5px',
                  cursor: 'pointer'
                }}
                onClick={() => {
                  let checkRef = ref(database, 'users/' + username);
                  onValue(checkRef, (snapshot) => {
                    let snap = snapshot.val();
                    if (snap.password == password) {
                      setLoginState('in');
                      setCurrentUser(snap);
                      snap.username = username;
                      localStorage.setItem('currentUser', JSON.stringify(snap));
                      setErrorState('');
                    } else {
                      setErrorState('');
                    }
                  });
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="black"
                >
                  <path d="M5 12L19 12M12 5l7 7-7 7" />
                </svg>
              </div>
            </div>
            {username && errorState == 'username' ? <p className="font-style" style={{color: '#C7130F', fontSize: 12}}>Invalid username</p> : null}
            {password && errorState == 'password' ? <p className="font-style" style={{color: '#C7130F', fontSize: 12}}>Incorrect password</p> : null}
          </div>
        ) : null}


          {/*<Routes>
            <Route
              path="/:username"
              element={<UserSpace currentUser={currentUser} turnRequest={turnRequest} handleRequest={handleRequest} handleTakeTurn={handleTakeTurn} />}
            />
            <Route
              path="/mirror"
              element={<PlayChunks />}
            />
            <Route
              path="/"
              element={
                currentUser ? (
                  // User is signed in, show a welcome message and Sign Out button
                  <div>
                    <div>
                      <label>
                        <input type="text" onKeyPress={handleKeyPress} value={word} onChange={handleWordChange} />
                      </label>
                    </div>
                    <button onClick={handleSignOut}>Sign Out</button>
                  </div>
                ) : (
                  // User is not signed in, show username and password inputs
                  <div>
                  <div>
                    <label>
                      Username:
                      <input type="text" value={username} onChange={(e) => setUsername(e.target.value)} />
                    </label>
                    <label>
                      Password:
                      <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
                    </label>
                    <button onClick={handleSignIn}>Sign In</button>
                </div>
                </div>
                )
              }
            />
          </Routes>*/}

          {/*<Link style={{color: 'white'}} to="/mirror">Mirror</Link>*/}

        </header>)}
      </div>
    </Router>
  );
}

export default App;
