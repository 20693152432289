// UserSpace.js
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getDatabase, ref, onValue } from 'firebase/database';

const database = getDatabase();

function UserSpace({ currentUser, turnRequest, handleRequest, handleTakeTurn }) {
  const { username } = useParams();
  const [currentWord, setCurrentWord] = useState('');

  useEffect(() => {
    const userWordRef = ref(database, `users/${username}/word`);
    const unsubscribe = onValue(userWordRef, (snapshot) => {
      const wordData = snapshot.val();
      if (wordData) {
        setCurrentWord(wordData.word);
      } else {
        setCurrentWord('No word available');
      }
    });

    return () => {
      unsubscribe();
    };
  }, [username]);

  const requestButton = (
    <button onClick={() => handleRequest(username)}>Request Turn</button>
  );

  const takeTurnButton = (
    <button onClick={() => handleTakeTurn(username)}>Take Turn</button>
  );

  const isHost = currentUser && currentUser.email === `${username}@achintya.org`;

  return (
    <div>
      <h2>{username}'s Space:</h2>
      <p>Current Word: {currentWord}</p>
      {isHost && turnRequest && requestButton}
      {!isHost && takeTurnButton}
    </div>
  );
}

export default UserSpace;
