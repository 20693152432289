// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyABlI72trCp1MhsllkGjtoxHVrtoa6KntU",
  authDomain: "ayanachintya.firebaseapp.com",
  databaseURL: "https://ayanachintya-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "ayanachintya",
  storageBucket: "ayanachintya.appspot.com",
  messagingSenderId: "232217240922",
  appId: "1:232217240922:web:26b38f35d8d8213bb43f63",
  measurementId: "G-D1HB6RHEZB"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);